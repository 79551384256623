// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axiosInstance from "../../utils/axios";
// import cookies from "js-cookie";
// const lang = cookies.get("i18next") || "en";

// const initialState = {
//   media: undefined,
//   currentMedia: null,
//   currentLocation: null,
//   error: null,
//   message: null,
//   loading: "idle",
//   typeIndex: 0,
//   tagIndex: 0,
//   sliderIndex: 0,
//   currentFullScreen: {
//     description: "",
//     visible: false,
//   },
// };

// export const getPropertyMedia = createAsyncThunk(
//   "api/properties_media",
//   async ({ ref_no, lang }) => {
//     let data;
//     try {
//       const response = await axiosInstance.get(
//         `/home/get_master_media?id=${ref_no}&lang=${lang}`
//       );
//       data = await response.data;
//       if (response.status === 200) {
//         return data;
//       }
//       throw new Error(response.statusText);
//     } catch (err) {
//       // const error = { message: err.payload.errors[0] };
//       const error = err;
//       return Promise.reject(error.message ? error.message : data?.message);
//     }
//   }
// );

// export const propertyMediaSlice = createSlice({
//   name: "propertyMedia",
//   initialState,
//   reducers: {
//     setCurrentData: (state, action) => {
//       state.currentMedia = action.payload;
//     },
//     setCurrentFiles: (state, action) => {
//       state.currentLocation = action.payload;
//     },
//     setTypeIndex: (state, action) => {
//       state.typeIndex = action.payload;
//     },
//     setTagIndex: (state, action) => {
//       state.tagIndex = action.payload;
//     },
//     setSliderIndex: (state, action) => {
//       state.sliderIndex = action.payload;
//     },
//     setFullScreen: (state, action) => {
//       if (action.payload?.description) {
//         state.currentFullScreen = {
//           ...state.currentFullScreen,
//           description: action.payload.description,
//         };
//       }
//       if (action.payload?.visible) {
//         state.currentFullScreen = {
//           ...state.currentFullScreen,
//           visible: action.payload.visible,
//         };
//       } else {
//         state.currentFullScreen = {
//           ...state.currentFullScreen,
//           visible: false,
//         };
//       }
//     },
//   },
//   extraReducers: {
//     [getPropertyMedia.pending]: (state) => {
//       state.error = null;
//       state.loading = "loading";
//     },
//     [getPropertyMedia.fulfilled]: (state, action) => {
//       const { media } = action.payload;
//       if (media) {
//         state.media = media;
//       }
//       state.loading = "idle";
//     },
//     [getPropertyMedia.rejected]: (state, action) => {
//       state.error = action.error.message;
//       state.loading = "idle";
//     },
//   },
// });

// export const {
//   setCurrentData,
//   setCurrentFiles,
//   setTypeIndex,
//   setTagIndex,
//   setFullScreen,
//   setSliderIndex,
// } = propertyMediaSlice.actions;

// export default propertyMediaSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import cookies from "js-cookie";
const lang = cookies.get("i18next") || "en";
const apiUrl = process.env.REACT_APP_API_URL; // Ensure this environment variable is set in your .env file
const systemUrl = process.env.REACT_APP_SYSTEM_URL; // Ensure this environment variable is set in your .env file

const initialState = {
  media: undefined,
  currentMedia: null,
  currentLocation: null,
  error: null,
  message: null,
  loading: "idle",
  typeIndex: 0,
  tagIndex: 0,
  sliderIndex: 0,
  currentFullScreen: {
    description: "",
    visible: false,
  },
};

export const getPropertyMedia = createAsyncThunk(
  "api/properties_media",
  async ({ ref_no, lang }) => {
    console.log("lang", lang);
    let data;
    try {
      const response = await axiosInstance.post(
        `${apiUrl}/home/get_media?lang=${lang}`,
        {
          id: ref_no,
          url: systemUrl,
        }
      );
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const propertyMediaSlice = createSlice({
  name: "propertyMedia",
  initialState,
  reducers: {
    setCurrentData: (state, action) => {
      state.currentMedia = action.payload;
    },
    setCurrentFiles: (state, action) => {
      state.currentLocation = action.payload;
    },
    setTypeIndex: (state, action) => {
      state.typeIndex = action.payload;
    },
    setTagIndex: (state, action) => {
      state.tagIndex = action.payload;
    },
    setSliderIndex: (state, action) => {
      state.sliderIndex = action.payload;
    },
    setFullScreen: (state, action) => {
      if (action.payload?.description) {
        state.currentFullScreen = {
          ...state.currentFullScreen,
          description: action.payload.description,
        };
      }
      if (action.payload?.visible) {
        state.currentFullScreen = {
          ...state.currentFullScreen,
          visible: action.payload.visible,
        };
      } else {
        state.currentFullScreen = {
          ...state.currentFullScreen,
          visible: false,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPropertyMedia.pending, (state) => {
        state.error = null;
        state.loading = "loading";
      })
      .addCase(getPropertyMedia.fulfilled, (state, action) => {
        const { media } = action.payload;
        if (media) {
          state.media = media;
        }
        state.loading = "idle";
      })
      .addCase(getPropertyMedia.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = "idle";
      });
  },
});

export const {
  setCurrentData,
  setCurrentFiles,
  setTypeIndex,
  setTagIndex,
  setFullScreen,
  setSliderIndex,
} = propertyMediaSlice.actions;

export default propertyMediaSlice.reducer;
